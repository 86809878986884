import Title from '@/components/Title';

export default function Home() {
  return (
    <main>
      <Title>Boolbel</Title>
      <p>The Creative school</p>
    </main>
  );
}
